import { Box, Button, Grid, Typography, Stack } from '@arquivei/atenas';

import { COMMUNITY_URL } from '../../constants';

const Community = () => {
  return (
    <Stack direction="row" width="100%" minHeight="70vh">
      <Stack justifyContent="center">
        <Grid container>
          <Grid>
            <Typography level="display2" textColor="common.white" gutterBottom>
              Comunidade developers
            </Typography>
            <Typography textColor="common.white" mt={1} maxWidth="600px">
              Tire dúvidas, encontre novas ideias, fale com outros
              desenvolvedores e descubra tudo que está acontecendo nas
              comunidades de desenvolvimento, acesse nossa página e aproveite
              tudo.
            </Typography>
          </Grid>
        </Grid>
        <Box>
          <Button
            component="a"
            href={COMMUNITY_URL}
            color="success"
            variant="solid"
            size="lg"
            sx={{
              mt: 4,
              backgroundColor: '#FF4719',
              '&:hover:not(.Joy-focused)': {
                backgroundColor: '#D13200',
              },
            }}
          >
            Ir para comunidade
          </Button>
        </Box>
      </Stack>
    </Stack>
  );
};

export default Community;
