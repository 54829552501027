import { Button, Grid, Typography, Stack } from '@arquivei/atenas';
import Image from 'next/image';

import womanUsingLaptop from '../../assets/woman-using-laptop.png';
import { DOCS_URL } from '../../constants';

const ApiDocumentationSection = () => {
  return (
    <Stack
      direction="row"
      component="section"
      data-testid="documentation-section"
      minHeight="50vh"
    >
      <Stack position="relative" justifyContent="end" width={1}>
        <Grid container>
          <Grid alignContent="center" md={6} xs={12}>
            <Typography level="display1" textColor="common.white" gutterBottom>
              Documentação de API
            </Typography>
            <Typography textColor="common.white" width="550px">
              Receba, controle e armazene seus documentos fiscais de maneira
              automatizada e segura, direcione seu financeiro e pare de perder
              tempo nas tarefas do dia a dia.
            </Typography>
            <Button
              component="a"
              href={DOCS_URL}
              variant="solid"
              size="lg"
              sx={{
                mt: 3,
                backgroundColor: '#FF4719',
                '&:hover:not(.Joy-focused)': {
                  backgroundColor: '#D13200',
                },
              }}
            >
              Ver documentação
            </Button>
          </Grid>
          <Grid
            md={6}
            display="flex"
            sx={{
              mb: 6,
              paddingTop: { xs: 12, md: 0 },
              justifyContent: 'end',
            }}
          >
            <Image
              src={womanUsingLaptop}
              alt="imagem de uma mulher com casaco laranja utilizando o computador portátil"
              objectPosition="center"
              data-testid="api-arquivei"
              quality={100}
              width="474"
              height="472"
              loading="eager"
              priority
            />
          </Grid>
        </Grid>
      </Stack>
    </Stack>
  );
};

export default ApiDocumentationSection;
