import apiXml from '../../assets/api-xml.svg';
import downloadFiles from '../../assets/download-files.svg';
import erpIntegrations from '../../assets/erp-integrations.svg';
import nfseOcr from '../../assets/nfse-ocr.svg';
import pdfFiles from '../../assets/pdf-files.svg';
import sandboxSecure from '../../assets/sandbox-secure.svg';

export const resourcesData = [
  {
    name: 'xmlDownload',
    img: downloadFiles,
    title: 'Download de XML de Documentos Fiscais',
    description:
      'Faça download de seus documentos fiscais em lotes e integre com seus sistemas.',
  },
  {
    name: 'pdfs',
    img: pdfFiles,
    title: 'DANFe, DACTe e DACTe OS',
    description:
      'Utilize nossa API de maneira simples e rápida para realizar o download de documentos fiscais em PDF.',
  },
  {
    name: 'api',
    img: apiXml,
    title: 'API',
    description:
      'Tenha ao seu alcance todos os eventos gerados para seus documentos e acompanhe cancelamentos, cartas de correção e outros.',
  },
  {
    name: 'ocr',
    img: nfseOcr,
    title: 'Processo de OCR para PDFs de NFS-e',
    description:
      'Tenha na Qive todas suas Notas de Serviço através do processo de OCR dos PDFs recebidos das Prefeituras.',
  },
  {
    name: 'integrations',
    img: erpIntegrations,
    title: 'Integrações',
    description:
      'Conte com a integração nos principais ERPs do mercado, integrando-os a nossa API.',
  },
  {
    name: 'sandbox',
    img: sandboxSecure,
    title: 'Ambiente Sandbox',
    description:
      'Teste todas as funcionalidades da API de maneira fácil e rápida.',
  },
];
