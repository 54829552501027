import { Box, Button, Grid, Typography, Stack } from '@arquivei/atenas';

import { HELP_URL } from '../../constants';

const NeedHelp = () => {
  return (
    <Stack direction="row" width="100%" minHeight="70vh">
      <Stack zIndex={1} justifyContent="center">
        <Grid container>
          <Grid md={6} xs={12}>
            <Typography level="display2" textColor="common.white" mb={1}>
              Precisa de ajuda?
            </Typography>
            <Typography textColor="common.white" width="90%">
              Conheça nossos canais de ajuda e suporte e não fique com dúvida,
              fale com um atendente especializado com agilidade, confira as
              formas para contato.
            </Typography>
          </Grid>
        </Grid>
        <Box>
          <Button
            component="a"
            href={HELP_URL}
            size="lg"
            sx={{
              mt: 4,
              backgroundColor: '#FF4719',
              '&:hover:not(.Joy-focused)': {
                backgroundColor: '#D13200',
              },
            }}
          >
            Fale conosco
          </Button>
        </Box>
      </Stack>
    </Stack>
  );
};

export default NeedHelp;
