import { Box, Button, Link as DSLink, Stack } from '@arquivei/atenas';
import { Hidden } from '@mui/material';
import Image from 'next/image';
import React from 'react';

import { GAEvent } from '../../../lib/gtag';
import logo from '../../assets/logo-qive-light.svg';
import {
  INDEX_URL,
  DOCS_URL,
  ARQUIVEI_JOBS_URL,
  HELP_URL,
  COMMUNITY_URL,
} from '../../constants';

const handleClick = () => {
  GAEvent('Go to account creation', {
    category: 'engagement',
    label: 'button "Teste nossa API"',
    value: 1,
  });
};

export default function HeaderDarken(): React.ReactElement {
  return (
    <Stack
      data-testid="header"
      sx={{
        borderRadius: 0,
        height: '4.75rem',
        justifyContent: 'center',
        paddingX: '2.5rem',
        backgroundColor: 'black',
      }}
    >
      <Stack spacing={2} direction="row" alignItems="center">
        <Box sx={{ flexGrow: 1 }}>
          <DSLink href={INDEX_URL}>
            <Image width="89" height="32" alt="Qive logo" src={logo} />
          </DSLink>
        </Box>
        <Stack alignContent="center" direction="row" spacing={1}>
          <Hidden smDown>
            <Stack direction="row" gap="1rem">
              <Button
                component="a"
                href={DOCS_URL}
                sx={{
                  backgroundColor: 'black',
                  color: 'white',
                  fontWeight: 400,
                  '&:hover:not(.Joy-focused)': {
                    backgroundColor: 'white',
                    color: 'black',
                  },
                }}
              >
                Documentação
              </Button>
              <Button
                component="a"
                href={COMMUNITY_URL}
                sx={{
                  backgroundColor: 'black',
                  color: 'white',
                  fontWeight: 400,
                  '&:hover:not(.Joy-focused)': {
                    backgroundColor: 'white',
                    color: 'black',
                  },
                }}
              >
                Comunidade
              </Button>
              <Button
                component="a"
                href={HELP_URL}
                sx={{
                  backgroundColor: 'black',
                  color: 'white',
                  fontWeight: 400,
                  '&:hover:not(.Joy-focused)': {
                    backgroundColor: 'white',
                    color: 'black',
                  },
                }}
              >
                Suporte
              </Button>
              <Button
                component="a"
                href={ARQUIVEI_JOBS_URL}
                sx={{
                  backgroundColor: 'black',
                  color: 'white',
                  fontWeight: 400,
                  '&:hover:not(.Joy-focused)': {
                    backgroundColor: 'white',
                    color: 'black',
                  },
                }}
              >
                Faça parte
              </Button>
            </Stack>
          </Hidden>

          <Button
            component="a"
            href="/sandbox"
            onClick={handleClick}
            size="lg"
            sx={{
              backgroundColor: 'black',
              border: '1px solid white',
              '&:hover:not(.Joy-focused)': {
                backgroundColor: 'white',
                color: 'black',
              },
            }}
          >
            Teste nossas APIs
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
}
