import { Box, Grid, Typography } from '@arquivei/atenas';
import { Card, CardContent, CardMedia } from '@mui/material';

import type { APIResource } from '../../types/resourceData';

import { resourcesData } from './mainResourcesData';

const MainResources = () => {
  return (
    <Box component="section" mt={13} data-testid="resources-section">
      <Grid maxWidth="40rem" mb={4} sx={{ ml: { xs: 6, md: 17 } }}>
        <Typography level="display2" mb={3}>
          Nossos principais recursos
        </Typography>
        <Typography textColor="text.secondary">
          Desde download e envio de documentos fiscais até integração com ERPs
          via API, conte com nossos recursos para resolver problemas do seu
          negócio.
        </Typography>
      </Grid>

      <Grid container justifyContent="center">
        {resourcesData.map((item: APIResource) => {
          return (
            <Grid key={item.name} mt={4} mr={4}>
              <Card
                data-testid="resources-card"
                sx={{
                  maxWidth: { xs: '100%', sm: '375px' },
                  height: '100%',
                  borderShadow: 'none',
                }}
                variant="outlined"
              >
                <CardMedia
                  component="img"
                  image={item.img.src}
                  width={item.img.width}
                  height={item.img.height}
                  sx={(theme) => ({
                    objectFit: 'none',
                    backgroundColor: theme.palette.grey[300],
                    minHeight: '140px',
                  })}
                />
                <CardContent sx={{ minHeight: '100px' }}>
                  <Typography component="p" level="h4" gutterBottom>
                    {item.title}
                  </Typography>
                  <Typography level="body2">{item.description}</Typography>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default MainResources;
